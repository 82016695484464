import React from 'react'
import { graphql } from 'gatsby'

import Layout from '../../components/layout'
import SEO from '../../components/seo'
import '../../components/antd/less/antMotionStyle.less'
import pic1 from '../../images/pin2.jpg'
import pic2 from '../../images/logo3.png'

const data = {
  society: [
    {
      title: '高级前端工程师（全职）',
      name: '经验3-5年/本科以上学历',
      address: '地点：北京',
    },
    {
      title: '高级JAVA后端开发工程师（全职）',
      name: '经验3-5年/本科以上学历',
      address: '地点：北京',
    },
    {
      title: '业务经理/高级业务经理',
      name: '经验3-5年/本科以上学历',
      address: '地点：北京',
    },
    {
      title: '业务拓展经理',
      name: '经验3-5年/本科以上学历',
      address: '地点：昆明',
    },
    {
      title: '测试工程师（派遣）',
      name: '经验3-5年/本科以上学历',
      address: '地点：昆明',
    },
  ],
  school: [
    {
      title: '设计助理（实习生）',
      name: '经验1年/本科',
      address: '地点：北京',
    },
    {
      title: '新媒体运营助理（实习生）',
      name: '经验1年/本科',
      address: '地点：北京',
    },
  ],
}

export default class SolutionList extends React.PureComponent {
  render() {
    const {
      data: { strapi = {} },
    } = this.props
    const { products, productGroups = [] } = strapi

    return (
      <Layout
        curIndex={-1}
        products={products}
        productGroups={productGroups}
        showFooter={true}
      >
        <SEO title='招聘' />
        <div className='core-tech-wrap pin-wrap'>
          <div className='main-img'></div>

          <div className='main-con'>
            <h2>加入我们</h2>
            <h3>和善于思考的人一起工作，创造非凡</h3>

            <div className='pin-box'>
              <div className='pin-title'>社会招聘</div>
              {data.society.map((item, index) => (
                <div className='pin-content' key={`${index + 1}`}>
                  <div className='pin-content-left'>
                    <div className='pin-content-left-title'>{item.title}</div>
                    <div className='pin-content-left-name'>{item.name}</div>
                    <div className='pin-content-left-address'>
                      {item.address}
                    </div>
                  </div>
                  <div className='pin-content-right'>
                    <img src={pic2} />
                  </div>
                </div>
              ))}
            </div>

            <div className='pin-box'>
              <div className='pin-title'>校园招聘</div>
              {data.school.map((item, index) => (
                <div className='pin-content' key={`${index + 1}`}>
                  <div className='pin-content-left'>
                    <div className='pin-content-left-title'>{item.title}</div>
                    <div className='pin-content-left-name'>{item.name}</div>
                    <div className='pin-content-left-address'>
                      {item.address}
                    </div>
                  </div>
                  <div className='pin-content-right'>
                    <img src={pic2} />
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className='core-tech-wrap solution-wrap'>
          <img src={pic1} />
        </div>
      </Layout>
    )
  }
}
export const query = graphql`
  query {
    strapi {
      solutions {
        id
        title
        image_link
        short_description
      }
      products {
        id
        title
        product_group {
          code
          name
        }
        type
      }
      productGroups {
        id
        code
        name
        type
      }
    }
  }
`
